.beach__sand__container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .sand_bg {
    display: block;
    height: 100%;
    width: 100%;
  }

  .sand_text {
    position: absolute;
    top: 10%;
    left: 5%;
    width: 90%;
    background-image: url("../../assets/images/anil_revamp_sets/SAND_TEXTBOX.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    //transform: translate(-50%, -50%);
    .sand_text_img {
      display: none;
      width: 100%;
      height: 100%;
      max-width: 500px;
      margin-bottom: 20px;
    }
  }
  .sand_wishes {
    // position: absolute;
    top: 14%;
    left: 5%;
    width: 90%;
    padding: 20px;
    .wish_sand_text {
      font-size: 14px;
      font-weight: normal;
    }
    //score 1
    .sand_shell_numbers-container {
      display: flex;
      position: relative;
      .shell_container_1 {
        span {
          font-size: 20px !important;
        }
      }
      .shell_div_1 {
        position: absolute;
        left: 11%;
        top: 17%;
      }
      .image_1 {
        width: 48%;
      }
    }
    //score 2
    .sand_shell_numbers-container {
      display: flex;
      position: relative;
      .shell_container_2 {
        position: absolute;
        left: 34%;
        span {
          font-size: 20px !important;
        }
      }
      .shell_div_2 {
        position: absolute;
        left: 46%;
        top: 17%;
      }
      .image_2 {
        width: 44%;
      }
    }
    //score 3
    .sand_shell_numbers-container {
      display: flex;
      position: relative;
      .shell_container_3 {
        left: 11%;
        position: absolute;
        left: 68%;
        span {
          font-size: 20px !important;
        }
      }
      .shell_div_3 {
        position: absolute;
        left: 82%;
        top: 23%;
      }
      .image_3 {
        width: 44%;
      }
    }
    .sand-shell-scores {
      padding: 10px 0;
      float: left;
      .sand-shell-score {
        width: 30%;
        float: left;
        .sand-shell-score-text,
        .sand-shell-score-icon {
          width: 50%;
          float: left;
          font-size: 14px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .shell_1 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 30%;
    left: 40%;
    .sand_shell1 {
      width: 40%;
    }
  }
  .shell-clicked1 {
    animation: shell-animation1 1s ease-in-out forwards;
  }
  @keyframes shell-animation1 {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-50%, 50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, 100%) scale(2);
      opacity: 0;
      display: none; /* Remove the image from the DOM */
    }
  }

  .shell_2 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 36%;
    left: 61%;
    .sand_shell2 {
      width: 41%;
    }
  }
  .shell-clicked2 {
    animation: shell-animation2 1s ease-in-out forwards;
  }
  @keyframes shell-animation2 {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-50%, 0) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, 100%) scale(2);
      opacity: 0;
      display: none; /* Remove the image from the DOM */
    }
  }
  .shell_3 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 29%;
    left: 77%;
    transform: translate(-50%, -50%);
    .sand_shell3 {
      // width: 10rem;
      width: 75%;
    }
  }
  .shell-clicked3 {
    animation: shell-animation3 1s ease-in-out forwards;
  }
  @keyframes shell-animation3 {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-50%, 0) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, 100%) scale(2);
      opacity: 0;
      display: none; /* Remove the image from the DOM */
    }
  }
  .shell_4 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 48%;
    left: 56%;
    .sand_shell4 {
      width: 41%;
    }
  }
  .shell-clicked4 {
    animation: shell-animation4 1s ease-in-out forwards;
  }
  @keyframes shell-animation4 {
    0% {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-100%, -50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-100%, -50%) scale(2);
      opacity: 0;
      display: none;
    }
  }
  .shell_5 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 57%;
    left: 67%;
    .sand_shell5 {
      width: 65%;
    }
  }
  .shell-clicked5 {
    animation: shell-animation5 1s ease-in-out forwards;
  }
  @keyframes shell-animation5 {
    0% {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-100%, -50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-100%, -50%) scale(2);
      opacity: 0;
      display: none;
    }
  }

  .shell_6 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 45%;
    left: 79%;
    .sand_shell6 {
      width: 93%;
    }
  }
  .shell-clicked6 {
    animation: shell-animation6 1s ease-in-out forwards;
  }
  @keyframes shell-animation6 {
    0% {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-100%, -50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-100%, -50%) scale(2);
      opacity: 0;
      display: none;
    }
  }
  .shell_7 {
    position: absolute;
    top: 74%;
    left: 37%;
    transform: translate(-50%, -50%);
    .sand_shell7 {
      /* width: 8rem; */
      width: 40%;
    }
  }
  .shell-clicked7 {
    animation: shell-animation7 1s ease-in-out forwards;
  }
  @keyframes shell-animation7 {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-50%, -100%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, -100%) scale(2);
      opacity: 0;
      display: none; /* Remove the image from the DOM */
    }
  }
  .shell_8 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 72%;
    left: 63%;
    .sand_shell8 {
      width: 50%;
    }
  }
  .shell-clicked8 {
    animation: shell-animation8 1s ease-in-out forwards;
  }
  
  @keyframes shell-animation8 {
    0% {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-100%, -50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-100%, -50%) scale(2);
      opacity: 0;
      display: none;
    }
  }
  .shell_9 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 64%;
    left: 64%;
    .sand_shell9 {
      width: 48%;
    }
  }
  .shell-clicked9 {
    animation: shell-animation9 1s ease-in-out forwards;
  }
  @keyframes shell-animation9 {
    0% {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-100%, -50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-100%, -50%) scale(2);
      opacity: 0;
      display: none;
    }
  }
  .shell_10 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 66%;
    left: 78%;
    .sand_shell10 {
      width: 77%;
    }
  }
  .shell-clicked10 {
    animation: shell-animation10 1s ease-in-out forwards;
  }
  @keyframes shell-animation10 {
    0% {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-100%, -50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-100%, -50%) scale(2);
      opacity: 0;
      display: none;
    }
  }
  .shell_11 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 74%;
    left: 78%;
    .sand_shell11 {
      width: 72%;
    }
  }
  .shell-clicked11 {
    animation: shell-animation11 1s ease-in-out forwards;
  }
  @keyframes shell-animation11 {
    0% {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-100%, -50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-100%, -50%) scale(2);
      opacity: 0;
      display: none;
    }
  }
  .shell_12 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 57%;
    left: 84%;
    .sand_shell12 {
      width: 91%;
    }
  }
  .shell-clicked12 {
    animation: shell-animation12 1s ease-in-out forwards;
  }
  @keyframes shell-animation12 {
    0% {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-100%, -50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-100%, -50%) scale(2);
      opacity: 0;
      display: none;
    }
  }
  .basket{
    position: absolute;
    width: 18%;
    height: 6%;
    top: 51%;
    left: 6%;
    border-radius: 50%;
    overflow: hidden;
    opacity: 0.6;
    > img.shellImage{
      position: absolute;
      width: 40%;
    }
  }
  // img[class*=' shell-clicked']{
  //   display: none;
  // }
}
